body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ag grid global styles */

.ag-header-cell .ag-header-cell-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.centered-columns .ag-header-cell-label {
  justify-content: center;
}

.ag-center-cols-clipper,
.ag-center-cols-container {
  min-height: 50px !important;
}

.highlightedHeader,
.highlightedHeader:hover {
  background-color: #3c3c3f !important;
  color: #ececec;
}

.highlightedHeader .ag-header-cell-resize::after {
  background-color: #636378;
}

.altHighlightedHeader {
  background-color: #e4e4e4 !important;
}

.altHighlightedHeader .ag-header-cell-resize::after {
  background-color: #bbb;
}

.highlightedCell {
  border-color: rgba(0, 0, 0, 0.03) !important;
}

.highlightedCell:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.045);
}

.ag-theme-alpine .ag-row-odd {
  background-color: #f9f9f9;
}

.footerRow {
  background-color: #ddd !important;
  font-weight: bold !important;
}

.editableCellContent {
  cursor: text;
}

.inactiveCellContent,
.inactiveRow .ag-cell,
.inactiveRow .ant-select-selection-item {
  color: #aeaecc;
}
